body {
  margin: 0;
  padding: 0;
  font-family: 'Nunito', sans-serif;
  /* font-family:  "Roboto", "Oxygen","Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; */
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #FFF; */
  /* background-color: #FFF; */
  color: #262626;
  height: 100vh;
  /* padding-top: 50px; */
}
* {
  border: none !important;
}
* {
  touch-action: manipulation;
}
.main-wrapper{
  /* background-color: #f0f1f2; */
}
.box{
  height: 160px;
  width: 100%;
  background: linear-gradient(120deg, #004effb8, rgb(255 160 0 / 67%));
  overflow: hidden;
  position: absolute;
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
}
p {
  margin-bottom: 0 !important;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
}
a img {
  border: none;
}
h4 {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}
a {
  color: #2b2b2b;
  text-decoration: none;
}
a:hover{
  color: #666666;
  text-decoration: none;
}
.nav-bar-icon{
  color: rgb(174, 176, 194);
  font-size: 20px;
}
.review-icon{
  color: #FFCA28;
  font-size: 20px;
}
.navbar-nav>li>a {
  padding-top: 20px !important;
  padding-bottom: 15px;
}
.app-wrapper{
  padding: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100vh;
  margin: auto;
}
.app-logo-header{
  height: 50px;
  margin: 8px;
}
.splash-logo-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -50px;
}
.splash-logo {
  width: 60px;
  height: 60px;
  margin-top: 25px;
  font-size: 21px;
  text-align: center;

  -webkit-animation: fadein 0.5s; /* Safari; Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.5s; /* Firefox < 16 */
  -ms-animation: fadein 0.5s; /* Internet Explorer */
  -o-animation: fadein 0.5s; /* Opera < 12.1 */
  animation: fadein 0.5s;
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.center {
 display: flex;
}
.footer{
  flex: 1;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  background-color: #FFFFFF;
}
.footer-ul{
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.footer li {
  float: left;
}
.footer li a {
  display: block;
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;
  padding: 16px;
  text-decoration: none;
}
.footer li a:hover {
  background-color: #FFFFFF;
}
.cam_container{
  height: 100%;
  background-image: url('../assets/images/cam_bg@2x.png');
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile-nav-bar{
  position: fixed;
  z-index: 1000;
  height: 48px;
  width: 100%;
  flex-direction: row;
  height: 48px;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #f5f5f7; */
  /* background-color: #E9E9EE; */
}
.app-logo {
  margin-top: 15px;
  margin-bottom: 10px;
  height: 70px;
  align-items: center;
  flex-direction: column;
}
.desktop-nav-bar{
  position: fixed;
  z-index: 1000;
  height: 60px;
  width: 100%;
  flex-direction: row;
  padding-left: 16px;
  padding-right: 16px;
  margin: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  /* background-color: #f5f5f7; */
  /* background-color: #E9E9EE; */
  justify-content: space-between
}
.desktop-nav-items-container {
  display: flex;
  margin-top: 8px;
  margin-bottom: 10px;
  height: 35px;
  /* width: 400px; */
  flex-direction: row;
  align-items: flex-end;
}
.app-logo-desktop {
  margin-top: 15px;
  margin-bottom: 10px;
  height: 70px;
  align-items: center;
  flex-direction: column;
}
.nav-bar-button {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
}

.add-new-tierly-btn{
  background: rgb(255,64,191);
  background: linear-gradient(90deg, rgba(255,64,191,1) 0%, rgba(64,140,255,1) 99%);
  /* background: linear-gradient(217deg, rgba(255,0,0,1), rgba(255,0,0,0) 70.71%),
            linear-gradient(127deg, rgba(0,255,0,1), rgba(0,255,0,0) 70.71%),
            linear-gradient(336deg, rgba(0,0,255,1), rgba(0,0,255,0) 70.71%); */
  border-radius: 20px;
  color: #ffffff;
  margin-right: 10px;
  cursor: pointer;
}
.profile-avatar-container{
  margin-left: 5px;
  margin-right: 30px;
  margin-top: 13px;
}
.navbar-default {
  background-color: #FFF;
  /* background-color: #E9E9EE; */
  /* border-color: #e7e7e7; */
  /* height: 80px; */
}
.no-margin {
  margin: 0 !important;
  padding: 0 !important;
}
.input-landing-margin {
  padding: 0 !important;
}
.container-fluid {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.header_img_browser{
  width: 600px;
}
.service-overview-cta-container{
  background-color:#E5E5E5;
  background-image: url('../assets/images/service_overview_bg@2x.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 900px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .navbar-default {
  background-color: #f5f5f7 !important;
  border-color: none !important;
}
.navbar-nav span[role="button"] {
  padding: 15px;
  display: inline-block;
  line-height: 20px;
  
}
.dropdown-menu {
  max-width: 100px !important;
}
.navbar-nav a{
  margin-top: 20px;
  background-color: #eeeeee;
  border-radius: 19px;
  margin-right: 10px ;
} */
.nav-profile-img-wrapper {
  height: 36px !important;
}
.nav-profile-img {
  border-radius: 50%;
  object-fit: cover;
  width: 32px;
  height: 32px;
  border: 2px solid rgba(187, 187, 187, 0.4);
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.nav-list-search {
  margin-top: 6px;
}
.panel {
  margin-bottom: 20px;
  background-color: #fff;
  border: none !important;
  border-radius: 4px;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.panel-default {
  border-color: none !important;
  position: absolute;
  width: 100px;
  padding-left: 10px;
  padding-top: 10px;
}
.app-title {
  font-size: 1.5em;
}
.app-intro {
  font-size: 40px;
  line-height: 1;
  color: #00e676;
}
.hero-type {
  margin-top: 10px;
}
.syntax_style {
  color: #b0bec5;
}
.dev-style {
  color: #242931;
}
.header_p {
  color: #78909c;
  font-size: 30px;
}
.sign-in-logo {
  margin-top: 5px;
  height: 55px;
}
.sign-up-logo {
  margin-top: 5px;
  height: 35px;
}
.app-header {
  margin-top: 60px;
}
.landing_img{
  text-align: center;
  width: 80%;
  margin: auto 0;
}
.logo {
  color: #212121;
  font-size: 20px;
}
.searchField {
  background-color: rgb(31, 35, 40);
  border-radius: 5px;
  border: 0;
  height: 35px;
  margin-bottom: 20px;
  padding: 5px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
  color: rgb(91, 113, 124);
}
.tag-links {
  font-size: 16px;
  color: #ffffff;
}
.join-the-comm {
  color: #2b3137;
  background-color: #00e676;
  border: none;
}
.sub-links p {
  font-size: 12px;
  color: #495a63;
}
.profile-wrapper {
  padding-top: 16px;
}
.sidebar-card {
  width: 100%;
  height: auto;
  display: flex;
  padding: 16px 16px 0;
}
.sidebar-img-wrapper {
  /* width: 100px; */
  /* height: 100px; */
  margin: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
.sidebar-user-img {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 32px;
  object-fit: cover;
  width: 100%;
  height: 380px;
  border: 2px solid rgba(187, 187, 187, 0.4);
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  
}
.sidebar-user-details-wrapper{
  width: 100%; 
  /* text-align: center; */
}
.sidebar-user-name {
  font-size: 30px;
  font-weight: 600;
  
}
.sidebar-user-links {
  padding: 16px;
}
.sidebar-card-user-location {
  color: #78909c;
  font-size: 12px;
  margin-bottom: 0 !important;
}
.sidebar-user-bio {
  font-size: 20px;
  /* line-height: 12px; */
}
.sidebar-user-stats {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 16px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 10px;
}
.create-new-list_text-area {
  background-color: rgb(236, 236, 236);
  width: 100%;
  border: 1px #e1e4e8 solid;
  border-radius: 6px;
  padding: 16px;
  margin-bottom: 12px;
}
.item-detail-wrapper {
  display: flex;
  flex-direction: row;
  margin-left: 8px;
  width: 100%;
}
.item-card {
  width: 100%;
  display: flex;
  flex: 1;
  padding: 8px;
  margin-top: 16px;
  margin-bottom: 16px;
  background-color: #fff;
  border-top: 2px #F8F8F8 solid;
  border-radius: 22px;
  align-items: center;
  /* box-shadow: 0 0px 4px 0 #bbbbbb50, 0 5px 0px 0 rgba(17, 27, 106, 0.064); */
  box-shadow: 0 0px 4px 0 #bbbbbb50, 0 6px 20px 0 rgba(17, 27, 106, 0.064);
  /* justify-content: flex-start; */
}
.item-card p {
  font-size: 10px;
}
.item-card-drag{
  width: 100%;
  display: flex;
  background-color: #fff;
}
.item-content{
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  /* margin: 0 !important; */
}
.item-link-container{
  display: flex;
  flex-direction: row;
}
.item-link-wrapper {
  /* display: flex; */
  /* flex: 1; */
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0 !important;
}
.item-link {
  width: 48px;
  height: 48px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1284FF;
}
.delete-link-icon {
  width: 48px;
  height: 48px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.delete-icon{
  color: #BBBBBB;
  font-size: 16px;
}
.item-link-icon-style{
  color: #FFFFFF;
  font-size: 16px;
}
.item-link-url{
  color: #B0BEC5;
}
.likes-container {
  width: 100%;
  flex: 1;
  display: flex;
  margin-right: 16;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.likes {
  margin-top: 12;
  width: 15px;
  height: 15px;
}
.profile-user-card-wrapper {
  height: 100%;
  width: 100%;
}
.profile-user-stats-wrapper {
  margin-top: 8px;
}
.avatar-card-style {
  margin-top: 16px;
  /* margin-bottom: 10px; */
  background-color: #fff;
  border-radius: 32px;
  /* box-shadow: 0 0px 4px 0 #bbbbbb50, 0 5px 0px 0 rgba(17, 27, 106, 0.064) */
  box-shadow: 0 0px 4px 0 #bbbbbb50, 0 6px 20px 0 rgba(17, 27, 106, 0.064);
}
.list-card-style {
  margin-bottom: 24px;
  /* margin-bottom: 10px; */
  background-color: #fff;
  border-radius: 32px;
  /* box-shadow: 0 0px 4px 0 #bbbbbb50, 0 5px 0px 0 rgba(17, 27, 106, 0.064) */
  box-shadow:  0 0px 4px 0 #bbbbbb50, 0 3px 9px 0 rgba(17, 27, 106, 0.064);
}
.list-card-comment-style {
  margin-top: 16px;
  margin-bottom: 10px;
  /* background-color: #fff; */
  border-radius: 22px;
  /* box-shadow: 0 0px 4px 0 rgba(52, 52, 52, 0.064), 0 6px 20px 0 rgba(106, 17, 17, 0.064); */
}
.list-card {
  display: flex;
  padding: 12px;
}
.list-view-card {
  flex-direction: row;
}
.list-profile-avatar {
  border-radius: 50%;
  object-fit: cover;
  width: 200px;
  height: 200px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.student-card-profile-avatar {
  border-radius: 50%;
  object-fit: cover;
  width: 36px;
  height: 36px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.profile-sidebar-avatar {
  border-radius: 32px;
  object-fit: cover;
  width: 100%;
  height: 80%;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.list-profile-avatar-wrapper {
  display: flex;
  /* align-items: center; */
  /* padding-left: 16px; */
  padding-right: 8px;
}
.student-profile-avatar-wrapper {
  /* display: flex; */
  align-items: center;
  /* padding-left: 16px; */
  padding-right: 8px;
}
.profile-avatar-wrapper {
  display: flex;
  align-items: center;
}
.list-profile-link-wrapper {
  flex-direction: column;
  /* padding:16px; */
}
.list-detail-wrapper {
  width: 100%;
  padding-right: 10px;
  display: flex;
  align-items: center;
  
}
.list-user-name {
  font-size: 14px;
  font-weight: 600;
  /* margin-bottom: 0 !important; */
  /* line-height: 15px; */
}
.list-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  /* margin-bottom: 4px; */
}
.list-level {
  font-size: 12px;
  font-weight: 700;
  /* line-height: 12px; */
}
.timestamp {
  font-size: 12px;
  color: #999;
  /* line-height: 10px; */
}
.list-items-wrapper {
  width: 100%;
  padding-bottom: 10px;
}
.card-style {
  background-color: #fff;
  border: 1px #e1e4e8 solid;
  border-radius: 22px;
  box-shadow: 0 1px 1px rgba(27, 31, 35, 0.1);
}

.edit-profile-btn {
  width: 100%;
  border-radius: 15px;
  height: 35px;
  color: #24292e;
  background-color: #eff3f6;
  background-image: linear-gradient(-180deg, #fafbfc, #eff3f6 90%);
}
.user-img-wrapper {
  width: 100%;
  height: 100%;
}
.user-img {
  border-radius: 50%;
  object-fit: cover;
  width: 200px;
  height: 200px;
  border: 2px solid #E4E4E4;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.user-name {
  font-size: 20px;
  line-height: 1;
}
.user-location {
  color: #78909c;
  font-size: 15px;
  line-height: 1;
}
.user-descriiption {
  color: #242931;
  font-size: 15px;
  line-height: 1.1;
}
.user-keywords {
  color: #78909c;
  font-size: 12px;
  line-height: 1;
}
.user-btn {
  background: #00e676;
  border-radius: 5px;
  border: none;
  height: 30px;
  width: 40%;
  margin: 10px;
}
/*////////////////////////*/
/*     Add Item Form      */
/*////////////////////////*/
.form-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.add-item-form {
  border-top: #e1e4e8 1px solid;
  padding-top: 10px;
  width: 100%;
}

.add-item-input {
  padding: 10px 10px 10px 5px;
  /* width:90%; */
  height: 35px;
  border: none;
  background-color: #f7f7f7;
}
.add-item-input input:focus {
  outline: none;
}
.add-item-btn {
  height: 100%;
  background: rgb(230, 230, 230);
}
.add-link-btn {
  height: 100%;
  background: rgb(230, 230, 230);
}
.add-link-text-field{
  width: 100%;
  background-color: #F7F7F7;
  margin-bottom: 10px;
}
.follow-btn {
  flex: 1;
  align-self: stretch;
  background-color: #FFFFFF;
  height: 28px;
  /* // width: 200; */
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  align-items: center;
  border: 1px solid #FFFFFF !important;
  cursor: pointer;
  box-shadow: 0 0px 4px 0 #bbbbbb50, 0 5px 0px 0 rgba(17, 27, 106, 0.064);
}
.follow-button-text {
  flex: 1;
  text-align: center;
  color: #212121;
  padding-top: 4px;
}
.unfollow-btn {
  flex-direction: row;
  align-self: stretch;
  background-color: #FFFFFF;
  height: 28px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  border: 1px solid #dbdbdb;
  cursor: pointer;
}
.unfollow-button-text {
  flex: 1;
  text-align: center;
  color: #262626;
  padding-top: 4px;
}
.comment-detail-wrapper {
  width: 100%;
  padding-right: 10px;
  flex-wrap: wrap;
  /* margin-top: 10px; */
  /* padding-bottom: 14px; */
  margin-left: 8px;
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
}
.comment-profile-avatar{
    object-fit: cover;
    width: 32px;
    height: 32px;
    clip-path: circle(16px at center);
}
.comment-display-name{
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  margin-bottom: 2px;
}
.comment-body-text-container{
  /* font-size: 14px; */
}
.comment-text{
  font-size: 14px;
  margin-bottom: 2px;
}
.add-comment-container {
  display: flex;
  flex-direction: row;
  height: 70px;
  margin-bottom: 30px;
}
.add-comment-profile-avatar {
  object-fit: cover;
  width: 32px;
  height: 32px;
  clip-path: circle(16px at center);
  margin-left: 16px;
  margin-top: 20px;
}
.add-comment-input-container-style {
  width: 100%;
}
.add-comment-input-textfield {
  background-color: #ffffff;
  height: 40px;
  width: 100%;
  border: none;
  margin-top: 14px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  font-size: 14px;
}
.add-comment-button-container {
  margin-right: 16px;
  margin-top: 25px;
  padding-left: 10px;
  height: 30px;
  border-left: 1px solid #E8EAF6;
}
.add-comment-div-style {
  font-size: 14px;
  color: #536dfe !important;
}
.add-comment-button-style :visited {
  color: #536dfe;
}
.remove-tierly-div-style{
  font-size: 14px;
  color: #EB606B !important;
}
.remove-comment-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.remove-comment-btn button {
  border: none;
}
.remove-btn-icon{
  color: #E0E0E0;
}
.remove-btn-none{
  display: none;
}
.tab-bar-wrapper {
  border-top: 1px solid #ECEBEC;
  background-color: #ffffff;
  border: 0;
  bottom: 0;
  padding-bottom: 22px; 
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  left: 0;
  position: fixed;
  right: 0;
  top: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 10;
}
.tab-bar-container {
  border-top: 1px solid #ECEBEC;
  padding-top: 10px;
  min-height: 44px;
  height: 100%;
  background-color: #fff;
}
.tab-bar-inner-wrapper {
  background-color: #fff;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  height: 100%;
}
.tab-bar-button {
  background-color: #fff;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.ux {
  background-color: #fff;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.tab-bar-icon {
  width: 24px;
  height: 24px;
}

.account-img-wrapper {
  width: 48px;
  height: 48px;
  margin: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
}
.account-user-img {
  border-radius: 50%;
  object-fit: cover;
  width: 48px;
  height: 48px;
  border: 2px solid #E4E4E4;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.mobile-profile-avatar {
  border-radius: 50%;
  object-fit: cover;
  width: 70px;
  height: 70px;
  border: 2px solid #E4E4E4;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.mobile-detail-wrapper {
  width: 100%;
  margin-left: 20px;
}
.profile-menu-bar-container {
  padding-top: 10px;
  margin-bottom: 16px;
  min-height: 44px;
  height: 100%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0px 4px rgba(187, 187, 187, 0.4);
}
.profile-menu-bar-inner-wrapper {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  height: 100%;
}
.profile-menu-bar-button {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.profile-menu-bar-ux{
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.profile-menu-bar-button:last-child {
  border-left: 1px #e1e4e8 solid;
}

.profile-menu-bar-icon {
  width: 20px;
  height: 20px;
}
.featured-card {
  display: flex;
  flex-direction: row;
  padding-top: 5px;
  padding-left: 16px;
  padding-right: 16px;
}
.featured-profile-avatar {
  border-radius: 50%;
  object-fit: cover;
  width: 32px;
  height: 32px;
  border: 2px solid rgba(187, 187, 187, 0.4);
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.featured-profile-avatar-wrapper{
  flex-direction: row;
  padding-top: 10px;
  padding-left: 4px;
  padding-right: 4px;
}

.top-friend-avatar-wrapper{
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 16px;
  /* height: 48px; */
}

.featured-avatar-style{
  padding-top: 5px;
  padding-left: 8px;
  padding-right: 8px;
  margin-bottom: 10px;
  position: relative;
  
}

.featured-avatar-img{
  border-radius: 50%;
  object-fit: cover;
  width: 48px;
  height: 48px;
  border: 3px solid #E4E4E4;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  
}

.top-friends-wrapper{
  height: 60px;
}

.top-public{
  padding-bottom: 60px;
}

.top-star-icon{
  color: #f8f8f8;
  font-size: 9px;
  margin-left: 2px;
  margin-top: 3px;
  position: absolute;
}

.top-star{
  margin-top: 25px;
  margin-left: 10px;
  position: absolute;
  top: 0;
  left: 0;
  /* background: #E4E4E4; */
  background: #F8F8F8;
  border-radius: 50%;
  width: 15px;
  height: 15px;
}
.top-friend-heart{
  width: 10px;
  height: 10px;
}
.top-friend-draggable{
  height: 100px;
}

.no-items-state-style{
  color: #b8b8b8;
  margin-bottom: 30px;
  margin-top: 30px;
}

.likes_share_comment_container {
  display: flex;
  justify-content: space-between;
  background-color: #FFF;
  align-items: center;
  /* width: 100%; */
  height: 70px;
  border-radius: 24px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 24px;
  padding-right: 24px;
  box-shadow: 0 0px 4px 0 #bbbbbb50, 0 5px 0px 0 rgba(17, 27, 106, 0.064);
  /* box-shadow: 0 0px 4px 0 #bbbbbb50, 0 6px 20px 0 rgba(17, 27, 106, 0.064); */
}
.img_box{
  overflow: hidden;
  background-size: cover;
  background-position: center;
  height: 100%;
  
}
.footer-banner{
  /* display: none; */
  color: #FFF;
  font-weight: 900;
  /* padding: 8px; */
  position: fixed;
  z-index: 2;
  bottom: 0px;
  height: 60px;
  width: 100%;
  margin: 0 auto;
  /* background-color: #E0E0E0; */
  background: linear-gradient(45deg, red, blue);
}
.request-invite_btn {
    color: #fff;
    font-weight: 900;
    width: 100%;
    background: linear-gradient(45deg,red,#00f);
    padding-top: 8px;
}


/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2) { 
    .list-title {
      font-size: 14px;
      font-weight: 900;
      line-height: 1;
      /* margin-bottom: 4px; */
    }
    .item-link-wrapper {
      /* display: flex; */
      /* flex: 1; */
      width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0 !important;
    }
    .item-content {
      font-size: 13px;
      font-weight: 600;
      line-height: 1;
    }
    .comment-text{
      line-height: 1.2;
    }
    .list-user-name {
      font-size: 12px;
    }
    .item-link {
      width: 30px;
      height: 30px;
      border-radius: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #1284FF;
    }
    .item-link-icon-style{
      color: #FFFFFF;
      font-size: 12px;
    }
  .list-profile-avatar {
      border-radius: 50%;
      object-fit: cover;
      width: 80px;
      height: 80px;
      box-sizing: border-box;
  }
  .footer-banner {
    color: #fff;
    font-weight: 900;
    position: fixed;
    z-index: 2;
    bottom: 0;
    height: 90px;
    width: 100%;
    margin: 0 auto;
    background: linear-gradient(45deg,red,#00f);
    padding-top: 8px;
  }
}